window.addEventListener("load", () => {
  loader = document.querySelector("#loader")
  loader.classList.toggle("active")

  h1 = document.querySelector("#cover .content")
  h1.classList.toggle("active")

  if (location.hash) {
    const element = document.querySelector(location.hash.replace("-scroll", ""))
    console.log(element)
    setTimeout(function() {
      UIkit.scroll(element, {
        offset: 100
      }).scrollTo(element)
    }, 500)
  }

  toggleNavs = document.querySelectorAll(".toggle-nav")
  menu = document.querySelector("nav > ul")
  nav = document.querySelector("nav")
  toggleNavs.forEach(element => {
    element.addEventListener("click", (e) => {
      menu.classList.toggle("visible")
      nav.classList.toggle("open")
    })
  })
})
